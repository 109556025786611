<template>
	<GMapMarker
		:position="position"
		:clickable="true"
		:icon="{
			path: getIcon(),
			size: { width: 60, height: 90, f: 'px', b: 'px' },
			scale: getScaleIcon(),
			rotation: parseInt(
				position && position.course
					? position.course
					: vehicle.gps_position.course
			),
			fillColor: this.getColor(vehicle.gps_position),
			fillOpacity: 1,
		}"
		@click="clickVehicle()"
		ref="marker_ref"
	>
	</GMapMarker>
</template>
<script>
	import VueGoogleMaps from "@fawmi/vue-google-maps";
	import moment from "moment";
	import {
		bus as busIcon,
		car as carIcon,
		truck as truckIcon,
		motorcycle as motorcycleIcon,
		van as vanIcon,
		genset as gensetIcon,
		mpv as mpvIcon,
		excavator as excavatorIcon,
		iconScale,
	} from "../_helpers/VehicleIcon";

	export default {
		computed: {
			google: VueGoogleMaps,
			sensors() {
				if (
					this.vehicle &&
					this.vehicle.gps_position &&
					this.vehicle.gps_position.sensors
				) {
					let results = "";
					this.vehicle.gps_position.sensors.map((item) => {
						results +=
							"<tr><th>" +
							item.name.charAt(0).toUpperCase() +
							item.name.slice(1) +
							"</th><td>:</td><td>" +
							item.value +
							"</td></tr>";
					});
					return results;
				}
				return "";
			},
		},
		data() {
			return {
				infowindow: null,
				timer: null,
				position: null,
				framePosition: [],
				frameIndex: 0,
				busIcon:
					"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
			};
		},
		unmounted() {
			if (this.timer) {
				clearInterval(this.timer);
			}
		},
		methods: {
			clickVehicle() {
				if (this.selectVehicle) {
					this.selectVehicle(this.vehicle);
				}
			},
			getIcon() {
				const Type = this.vehicle.vehicle_type ?? "";
				let icon = busIcon;
				const t = Type.toLowerCase();
				if (t === "truck") {
					icon = truckIcon;
				} else if (t === "mpv") {
					icon = mpvIcon;
				} else if (t === "motorcycle") {
					icon = motorcycleIcon;
				} else if (t === "car") {
					icon = carIcon;
				} else if (t === "van") {
					icon = vanIcon;
				} else if (t === "genset") {
					icon = gensetIcon;
				} else if (t === "excavator") {
					icon = excavatorIcon;
				}

				return icon;
			},
			getScaleIcon() {
				const icon = this.getIcon();
				return iconScale(icon);
			},
			getColor(gps_position) {
				if (gps_position.speed > 0) {
					return "#3bb143";
				}
				if (
					gps_position &&
					gps_position.sensors &&
					gps_position.sensors.length > 0
				) {
					let acc = gps_position.sensors.filter((item) => item.type === "acc");
					if (acc.length > 0) {
						if (acc[0].val === true) {
							return "#007bff";
						}
					}
				}
				return "#ef5350";
			},
			animateMoving(nextState) {
				this.framePosition = [];
				this.frameIndex = 0;
				if (this.timer) {
					clearInterval(this.timer);
				}
				const that = this;
				if (nextState && nextState.speed > 0) {
					for (let percent = 0; percent < 1; percent += 0.01) {
						let nextLat = nextState.lat;
						let nextLng = nextState.lng;
						let nextCourse = that.vehicle.gps_position.course;
						let up_down = -1;
						let left_right = -1;
						if (that.position.lat != nextState.lat) {
							nextLat =
								that.position.lat +
								(nextState.lat - that.position.lat) * percent;
							if (that.position.lat > nextState.lat) {
								up_down = 1;
							} else {
								up_down = 0;
							}
						}
						if (that.position.lng != nextState.lng) {
							nextLng =
								that.position.lng +
								(nextState.lng - that.position.lng) * percent;
							if (that.position.lng > nextState.lng) {
								left_right = 0;
							} else {
								left_right = 1;
							}
						}
						if (up_down != -1) {
							if (up_down == 0) {
								if (left_right == 0) {
									if (that.vehicle.gps_position.course > 270) {
										nextCourse = that.vehicle.gps_position.course;
									} else {
										nextCourse = 315;
									}
								} else if (left_right == 1) {
									if (
										that.vehicle.gps_position.course > 0 &&
										that.vehicle.gps_position.course < 90
									) {
										nextCourse = that.vehicle.gps_position.course;
									} else {
										nextCourse = 45;
									}
								} else {
									if (
										that.vehicle.gps_position.course > 315 &&
										that.vehicle.gps_position.course < 45
									) {
										nextCourse = that.vehicle.gps_position.course;
									} else {
										nextCourse = 0;
									}
								}
							} else if (up_down == 1) {
								if (left_right == 0) {
									if (
										that.vehicle.gps_position.course > 180 &&
										that.vehicle.gps_position.course < 270
									) {
										nextCourse = that.vehicle.gps_position.course;
									} else {
										nextCourse = 225;
									}
								} else if (left_right == 1) {
									if (
										that.vehicle.gps_position.course > 90 &&
										that.vehicle.gps_position.course < 180
									) {
										nextCourse = that.vehicle.gps_position.course;
									} else {
										nextCourse = 135;
									}
								} else {
									if (
										that.vehicle.gps_position.course > 135 &&
										that.vehicle.gps_position.course < 225
									) {
										nextCourse = that.vehicle.gps_position.course;
									} else {
										nextCourse = 180;
									}
								}
							}
						} else if (left_right == 0) {
							if (
								that.vehicle.gps_position.course > 225 &&
								that.vehicle.gps_position.course < 315
							) {
								nextCourse = that.vehicle.gps_position.course;
							} else {
								nextCourse = 270;
							}
						} else if (left_right == 1) {
							if (
								that.vehicle.gps_position.course > 45 &&
								that.vehicle.gps_position.course < 135
							) {
								nextCourse = that.vehicle.gps_position.course;
							} else {
								nextCourse = 90;
							}
						} else {
							nextCourse = that.vehicle.gps_position.course;
						}
						that.framePosition.push({
							lat: nextLat,
							lng: nextLng,
							course: nextCourse,
						});
					}
					if (that.framePosition.length > that.frameIndex) {
						this.timer = setInterval(function() {
							that.position.lat = that.framePosition[that.frameIndex].lat;
							that.position.lng = that.framePosition[that.frameIndex].lng;
							that.position.course = that.framePosition[that.frameIndex].course;
							if (that.frameIndex < that.framePosition.length - 1) {
								that.frameIndex++;
							}
						}, process.env.VUE_APP_RELOAD_TIME / 100);
					}
				}
			},
		},
		props: [
			"vehicle",
			"gmap",
			"selectVehicle",
			"selected_vehicle",
			"gps_position",
		],
		watch: {
			selected_vehicle(nextState) {
				if (nextState !== null && this.vehicle.id === nextState.id) {
					const self = this;
					this.gmap.$mapPromise.then(() => {
						this.$nextTick().then(() => {
							if (self.infowindow !== null) {
								self.infowindow.setMap(null);
								self.infowindow = null;
							}
							self.infowindow = new window.google.maps.InfoWindow({
								content:
									"<table>" +
									"<tr><th>Vehicle No</th><td>:</td><td>" +
									self.vehicle.vehicle_no +
									"</td></tr>" +
									"<tr><th>Vehicle Code</th><td>:</td><td>" +
									self.vehicle.vehicle_code +
									"</td></tr>" +
									"<tr><th>Speed</th><td>:</td><td>" +
									self.vehicle.gps_position.speed +
									" km/h</td></tr>" +
									"<tr><th>Status</th><td>:</td><td>" +
									self.vehicle.gps_position.online +
									"</td></tr>" +
									self.sensors +
									"<tr><th>Stop Duration</th><td>:</td><td>" +
									self.vehicle.gps_position.stop_duration +
									"</td></tr>" +
									'<tr style="vertical-align:top;"><th>Address</th><td>:</td><td>' +
									nextState.gps_position.address +
									"</td></tr>" +
									"<tr><th>Last Update</th><td>:</td><td>" +
									moment
										.utc(self.vehicle.gps_position.time)
										.local()
										.format("lll") +
									"</td></tr>" +
									"</table>",
								maxWidth: 300,
							});
							self.infowindow.open({
								map: self.gmap.$mapObject,
								anchor: self.$refs.marker_ref.$markerObject,
							});
						});
					});
				} else {
					const self = this;
					this.gmap.$mapPromise.then(() => {
						this.$nextTick().then(() => {
							if (self.infowindow !== null) {
								self.infowindow.setMap(null);
								self.infowindow = null;
							}
						});
					});
				}
			},
			gps_position(nextState, prevState) {
				if (nextState !== prevState) {
					this.animateMoving(nextState);
				}
			},
		},
		mounted() {
			if (
				this.vehicle.gps_position.tail &&
				this.vehicle.gps_position.tail.length > 0
			) {
				this.position = {
					lat: parseFloat(this.vehicle.gps_position.tail[0].lat),
					lng: parseFloat(this.vehicle.gps_position.tail[0].lng),
					course: this.vehicle.gps_position.course,
					speed: this.vehicle.gps_position.speed,
				};
				this.animateMoving(this.vehicle.gps_position);
			} else {
				this.position = { ...this.vehicle.gps_position };
			}
			if (
				this.selected_vehicle !== null &&
				this.vehicle.id === this.selected_vehicle.id
			) {
				const self = this;
				this.gmap.$mapPromise.then(() => {
					this.$nextTick().then(() => {
						if (self.infowindow !== null) {
							self.infowindow.setMap(null);
							self.infowindow = null;
						}
						self.infowindow = new window.google.maps.InfoWindow({
							content:
								"<table>" +
								"<tr><th>Vehicle No</th><td>:</td><td>" +
								self.vehicle.vehicle_no +
								"</td></tr>" +
								"<tr><th>Vehicle Code</th><td>:</td><td>" +
								self.vehicle.vehicle_code +
								"</td></tr>" +
								"<tr><th>Speed</th><td>:</td><td>" +
								self.vehicle.gps_position.speed +
								" km/h</td></tr>" +
								"<tr><th>Status</th><td>:</td><td>" +
								self.vehicle.gps_position.online +
								"</td></tr>" +
								self.sensors +
								"<tr><th>Stop Duration</th><td>:</td><td>" +
								self.vehicle.gps_position.stop_duration +
								"</td></tr>" +
								'<tr style="vertical-align:top;"><th>Address</th><td>:</td><td>' +
								self.selected_vehicle.gps_position.address +
								"</td></tr>" +
								"<tr><th>Last Update</th><td>:</td><td>" +
								moment
									.utc(self.vehicle.gps_position.time)
									.local()
									.format("lll") +
								"</td></tr>" +
								"</table>",
							maxWidth: 300,
						});
						self.infowindow.open({
							map: self.gmap.$mapObject,
							anchor: self.$refs.marker_ref.$markerObject,
						});
					});
				});
			}
		},
	};
</script>
