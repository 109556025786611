export const bus = `M45.17,0c-15.52,0-30.73,1.93-38.52,4.1c-2.1,0.57-3.83,2.57-3.83,5.01V317.45c0,2.44,1.73,4.44,3.83,5.01
           c7.8,2.17,22.99,4.1,38.52,4.1s30.73-1.93,38.52-4.1c2.1-0.57,3.83-2.57,3.83-5.01V9.11c0-2.44-1.73-4.44-3.83-5.01
           C75.9,1.93,60.69,0,45.17,0z M32.75,74.94h24.94c1.04,0,1.88,0.84,1.88,1.88v9.75c0,1.04-0.84,1.88-1.88,1.88H32.75
           c-1.04,0-1.88-0.84-1.88-1.88v-9.75C30.88,75.78,31.72,74.94,32.75,74.94z M28.75,56.48c0-0.83,0.67-1.5,1.5-1.5h29.84
           c0.83,0,1.5,0.67,1.5,1.5v8.07c0,0.83-0.67,1.5-1.5,1.5H30.25c-0.83,0-1.5-0.67-1.5-1.5V56.48z M31.19,237.76h27.97
           c1.26,0,2.28,1.02,2.28,2.28v62.18c0,1.26-1.02,2.28-2.28,2.28H31.19c-1.26,0-2.28-1.02-2.28-2.28V240.04
           C28.91,238.78,29.93,237.76,31.19,237.76z`;
export const truck =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const car =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const mpv =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const motorcycle =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const genset =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const van =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const excavator =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";

export const optionVehicle = [
	{
		label: "Bus",
		value: "Bus",
		icon: bus,
		type: "path",
		scale: 0.1,
	},
	{
		label: "Car",
		value: "Car",
		icon: car,
		type: "path",
		scale: 0.7,
	},
	{
		label: "Motorcycle",
		value: "Motorcycle",
		icon: motorcycle,
		type: "path",
		scale: 0.7,
	},
	{
		label: "MPV",
		value: "MPV",
		icon: mpv,
		type: "path",
		scale: 0.7,
	},
	{
		label: "Truck",
		value: "Truck",
		icon: truck,
		type: "path",
		scale: 0.7,
	},
	{
		label: "Van",
		value: "Van",
		icon: van,
		type: "path",
		scale: 0.7,
	},
	{
		label: "Genset",
		value: "Genset",
		icon: genset,
		type: "path",
		scale: 0.7,
	},
	{
		label: "Excavator",
		value: "Excavator",
		icon: excavator,
		type: "path",
		scale: 0.7,
	},
];

export function iconType($iconString) {
	const search = optionVehicle.filter((item) => item.icon === $iconString);

	if (search.length > 0) {
		return search[0].type;
	}

	return "path";
}

export function iconScale($iconString) {
	const search = optionVehicle.filter((item) => item.icon === $iconString);

	if (search.length > 0) {
		return search[0].scale;
	}

	return "path";
}
