<template>
	<form @submit.prevent="handleSubmit">
		<div class="row">
			<div class="form-group col-sm-12 required">
				<label for="parent_id">Account:</label>
				<Select2
					name="account_id"
					v-model.number="vehicle.account_id"
					:options="accountOptions"
					@change="onChange($event)"
					@select="onChange($event)"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="vehicle_no">Vehicle No:</label>
				<input
					class="form-control"
					required=""
					name="vehicle_no"
					type="text"
					v-model="vehicle.vehicle_no"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="vehicle_code">Vehicle Code:</label>
				<input
					class="form-control"
					required=""
					name="vehicle_code"
					type="text"
					v-model="vehicle.vehicle_code"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="total_seat">Total Seat:</label>
				<input
					class="form-control"
					name="total_seat"
					type="number"
					v-model.number="vehicle.total_seat"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="machine_no">Machine Number:</label>
				<input
					class="form-control"
					name="machine_no"
					type="text"
					v-model="vehicle.machine_no"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="chassis_no">Chassis Number:</label>
				<input
					class="form-control"
					name="chassis_no"
					type="text"
					v-model="vehicle.chassis_no"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="capacity">Capacity (cc):</label>
				<input
					class="form-control"
					name="capacity"
					type="number"
					v-model.number="vehicle.capacity"
					min="0"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="type">Type:</label>
				<select
					class="form-control"
					name="type"
					required=""
					v-model="vehicle.type"
				>
					<option
						v-for="typeV in vehicleOptions"
						:key="typeV.value"
						:value="typeV.value"
						style="color:black;"
					>
						{{ typeV.label }}
					</option>
				</select>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="odometer">Odometer:</label>
				<input
					class="form-control"
					required=""
					name="odometer"
					type="number"
					v-model.number="vehicle.odometer"
					step="any"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-4">
				<label for="color">Color:</label>
				<input
					class="form-control"
					name="color"
					type="text"
					v-model="vehicle.color"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-4">
				<label for="manufacture">Manufacturer:</label>
				<input
					class="form-control"
					name="manufacture"
					type="text"
					v-model="vehicle.manufacture"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-4">
				<label for="manufacture_year">Manufacture Year:</label>
				<input
					class="form-control"
					name="manufacture_year"
					type="number"
					v-model="vehicle.manufacture_year"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="stnk_number">STNK Number:</label>
				<input
					class="form-control"
					name="stnk_number"
					type="text"
					v-model="vehicle.stnk_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="stnk_date">STNK Date:</label>
				<input
					class="form-control"
					name="stnk_date"
					type="date"
					v-model="vehicle.stnk_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="keur_number">KEUR Number:</label>
				<input
					class="form-control"
					name="keur_number"
					type="text"
					v-model="vehicle.keur_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="keur_date">KEUR Date:</label>
				<input
					class="form-control"
					name="keur_date"
					type="date"
					v-model="vehicle.keur_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="kp_number">KP Number:</label>
				<input
					class="form-control"
					name="kp_number"
					type="text"
					v-model="vehicle.kp_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="kp_date">KP Date:</label>
				<input
					class="form-control"
					name="kp_date"
					type="date"
					v-model="vehicle.kp_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="sipa_number">SIPA Number:</label>
				<input
					class="form-control"
					name="sipa_number"
					type="text"
					v-model="vehicle.sipa_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="sipa_date">SIPA Date:</label>
				<input
					class="form-control"
					name="sipa_date"
					type="date"
					v-model="vehicle.sipa_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="ibm_number">IBM Number:</label>
				<input
					class="form-control"
					name="ibm_number"
					type="text"
					v-model="vehicle.ibm_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="ibm_date">IBM Date:</label>
				<input
					class="form-control"
					name="ibm_date"
					type="date"
					v-model="vehicle.ibm_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="other_number">Other Number:</label>
				<input
					class="form-control"
					name="other_number"
					type="text"
					v-model="vehicle.other_number"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="other_number_date">Other Number Date:</label>
				<input
					class="form-control"
					name="other_number_date"
					type="date"
					v-model="vehicle.other_number_date"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-6 required">
				<label for="status">Status:</label>
				<select
					class="form-control"
					name="status"
					v-model.number="vehicle.status"
				>
					<option value="1" style="color:black;">Active</option>
					<option value="2" style="color:black;">Inactive</option>
				</select>
			</div>
			<div class="form-group col-sm-12 col-md-6">
				<label for="gps_id">GPS:</label>
				<Select2
					name="gps_id"
					v-model.number="vehicle.gps_id"
					:options="gpsOptions"
				/>
			</div>
			<InputImage label="Picture" v-on:selected-image="setImage" />
			<div class="form-group col-sm-12">
				<input class="btn btn-primary" type="submit" value="Save" />
				<router-link class="btn btn-default ml-1" to="/vehicles"
					>Cancel</router-link
				>
			</div>
		</div>
		<div
			v-if="this.onRequest"
			class="modal-backdrop fade show"
			style="display:flex; align-items: center; justify-content: center;"
		>
			<img :src="require('../assets/loading.gif')" />
		</div>
	</form>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import InputImage from "../components/InputImage.vue";
	import Select2 from "vue3-select2-component";
	import { optionVehicle } from "../_helpers/VehicleIcon";
	export default {
		computed: {
			...mapState("accounts", ["accounts"]),
			...mapState("gps", ["gpss"]),
			...mapState("vehicles", ["onRequest"]),
		},
		data() {
			return {
				vehicle: {
					account_id: "",
					vehicle_no: null,
					vehicle_code: null,
					stnk_number: null,
					machine_no: null,
					chassis_no: null,
					capacity: null,
					gps_id: null,
					status: 1,
					total_seat: 0,
					type: "Bus",
					color: null,
					manufacture: null,
					manufacture_year: null,
					odometer: 0,
					stnk_date: null,
					keur_number: null,
					keur_date: null,
					kp_number: null,
					kp_date: null,
					sipa_number: null,
					sipa_date: null,
					ibm_number: null,
					ibm_date: null,
					other_number: null,
					other_number_date: null,
				},
				file_image: null,
				gpsOptions: [],
				accountOptions: [],
				vehicleOptions: optionVehicle,
			};
		},
		components: {
			InputImage,
			Select2,
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				this.vehicle.file_image = this.file_image;
				this.add(this.vehicle);
			},
			onChange(event) {
				this.getGps({ account_id: parseInt(event.id) });
				this.vehicle.gps_id = null;
			},
			...mapActions("vehicles", ["add"]),
			...mapActions("accounts", { getAccounts: "get_all" }),
			...mapActions("gps", { getGps: "get_gps_by_account" }),
			setImage(image) {
				this.file_image = image;
			},
		},
		created() {
			this.$emit("onChildInit", "Add New Vehicle");
			this.getAccounts();
		},
		watch: {
			accounts(nextState, prevState) {
				if (nextState !== prevState) {
					this.accountOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.accountOptions.push({
								id: item.id,
								text: item.account_name,
							});
						});
					}
					return;
				}
			},
			gpss(nextState, prevState) {
				if (nextState !== prevState) {
					this.gpsOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.gpsOptions.push({
								id: item.id,
								text: item.imei + " - " + item.serial_number,
							});
						});
					}
					return;
				}
			},
		},
	};
</script>
